<template>
  <div id="page-tree-demo">
    <vs-prompt
      class="z-index"
      title="Настройки категории"
      @accept="editCategory(data)"
      accept-text="Сохранить"
      cancel-text="Отмена"
      :active.sync="optionsState"
    >
      <div id="category-prompt">
        <vs-input label="Название" v-model="data.title" class="mt-5 w-full" />
        <vs-divider position="left">Связанные опции</vs-divider>
        <div
          class=""
          v-for="(option, index) in data.options"
          :key="'options' + index"
        >
          <span class="pr-3">{{ index + 1 }}</span>
          <vs-input
            placeholder="Название"
            v-model="option.name"
            :class="{ 'mt-5': index !== 0 }"
            class="inline-block"
            name=""
          />
          <vs-checkbox
            class="inline-block"
            v-model="option.in_filter"
            title="Участвует в фильтрации"
          ></vs-checkbox>
          <vs-checkbox
            class="inline-block"
            v-model="option.in_compare"
            title="Участвует в сравнении"
          ></vs-checkbox>
          <vs-button
            @click="data.options.splice(index, 1)"
            title="Удалить"
            class="inline-block"
            color="primary"
            type="flat"
            icon-pack="feather"
            icon="icon-minus"
          ></vs-button>
          <vs-divider position="left">Стандартные значения</vs-divider>
          <div
            class=""
            v-for="(stand_value, indx) in option.standard"
            :key="'option.standard' + indx"
          >
            <vs-input
              placeholder="Значение"
              v-model="stand_value.value"
              class="mt-5 inline-block"
              name=""
            />
            <vs-button
              @click="option.standard.splice(indx, 1)"
              title="Удалить"
              class="inline-block"
              color="primary"
              type="flat"
              icon-pack="feather"
              icon="icon-minus"
            ></vs-button>
          </div>
          <vs-button
            @click="option.standard.push({ value: '' })"
            class="mt-3"
            size="small"
          >
            Добавить стандартное значение
          </vs-button>
        </div>
        <vs-button
          @click="
            data.options.push({
              name: '',
              in_filter: false,
              in_compare: false,
              standard: [],
            })
          "
          class="mt-3"
          size="small"
          >Добавить опцию
        </vs-button>
      </div>
    </vs-prompt>
    <vs-card>
      <h4 style="padding: 10px 5px 20px 10px">Все категории</h4>
      <div id="test">
        <!-- <vue-drag-tree :data='treeData' :allowDrag='allowDrag' :allowDrop='allowDrop' :defaultText='"New Node"'
                               @drop="dropHandler"
                               @edit="onEdit"
                               @add="onAdd"
                               @delete="onDelete"
                >
                </vue-drag-tree> -->
        <v-tree
          ref="tree"
          :data="treeData"
          :multiple="true"
          :draggable="true"
          :scoped="true"
          :tpl="tpl"
          :halfcheck="false"
          :allowGetParentNode="true"
          @drag-node-end="drugTree"
          @node-expand="expandTree"
        />
      </div>
      <vs-button @click="addCategory(0)" class="mt-3">Добавить</vs-button>
    </vs-card>
  </div>
</template>

<script>
import axios from '@axios'
import VueDragTree from '../../global-components/vue-drag-tree/src/VueDragTree.vue'
import 'vue-tree-halower/dist/halower-tree.min.css' // you can customize the style of the tree
import VTree from 'vue-tree-halower'
import { EditIcon, TrashIcon } from 'vue-feather-icons'
import { async } from 'vuesax'

export default {
  components: { VueDragTree, VTree, EditIcon, TrashIcon },
  data() {
    return {
      backgroundLoading: 'primary',
      colorLoading: '#fff',
      currentCat: 0,
      optionsState: false,
      searchword: '',
      treeData: [],
      data: [],
    }
  },
  computed: {},
  methods: {
    tpl(...args) {
      let { 0: node, 2: parent, 3: index } = args
      let titleClass = node.selected ? 'node-title node-selected' : 'node-title'
      if (node.searched) titleClass += ' node-searched'
      return (
        <span>
          <button class="treebtn1" onClick={() => this.addSubCategory(node)}>
            + катег..
          </button>
          <span
            class={titleClass}
            domPropsInnerHTML={node.title}
            onClick={() => {
              this.$refs.tree.nodeSelected(node.id)
            }}
          ></span>
          <button
            class="treebtn2"
            onClick={() => this.asyncLoad(node, parent, index)}
          >
            Изменить
          </button>
          <button
            class="treebtn3"
            onClick={() => this.deleteCategory(node, parent, index)}
          >
            Удалить
          </button>
        </span>
      )
    },
    async asyncLoad(node, parent, index) {
      let name = prompt('Введите название категории')
      if (!name) return false

      this.$vs.loading({
        container: this.$refs.test,
        scale: 0.45,
      })
      try {
        await axios.put(`category/${node.id}`, { ...node, title: name })
        // const parentNode = node.parent
        //   ? node.parent()
        //   : await this.getCategory(
        //       this.$refs.tree.getNodes({ id: node.parent_id })[0].id,
        //     )
        // console.log('parentNode', parentNode)
        // const result = this.$refs.tree.searchNodes(parentNode.title)
        // console.log('result', result)
        // const index = parent.children.findIndex(e => e.id === node.id)
        this.$refs.tree.delNode(node, parent, index)
        this.$refs.tree.addNode(parent, { ...node, title: name })
        this.$vs.loading.close(this.$refs.test)
        // this.getCategories();
      } catch (error) {
        console.log(error)
        this.$vs.loading.close(this.$refs.test)
        this.getCategories()
      }
    },
    search() {
      this.$refs.tree.searchNodes(this.searchword)
    },
    async drugTree(data) {
      this.$vs.loading({
        container: this.$refs.test,
        scale: 0.45,
      })
      try {
        await axios.put(`category/${data.dragNode.id}`, {
          ...data.dragNode,
          parent_id: data.targetNode.id,
        })
        this.$vs.loading.close(this.$refs.test)
        // this.getCategories();
      } catch (error) {
        this.$vs.loading.close(this.$refs.test)
        this.getCategories()
      }
    },
    allowDrag(model, component) {
      if (model.title === 'Все категории') {
        // can't be dragged
        return false
      }
      // can be dragged
      return true
    },
    allowDrop(model, component) {
      if (model.title === 'Node 2-2') {
        // can't be placed
        return false
      }
      // can be placed
      return true
    },
    onEdit(model) {
      this.data = model
      this.optionsState = true
    },
    onDelete(model) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        acceptText: 'Да',
        cancelText: 'Отмена',
        title: `Удаление категории`,
        text: 'Вы уверены, что хотите удалить категорию?',
        accept: () => {
          this.deleteCategory(model.id)
        },
      })
    },
    onAdd(model) {
      this.addCategory(model.id)
    },
    moveCategory(id, to) {
      if (id === to) return false
      this.$vs.loading({
        container: this.$refs.test,
        scale: 0.45,
      })
      axios
        .post(`/api/shop/categories/move/${id}/into/${to}`)
        .then(response => {
          this.$vs.loading.close(this.$refs.test)
          this.getCategories()
        })
        .catch(error => {
          console.log(error)
        })
    },
    dropHandler(model, from, to) {
      this.moveCategory(from.model.id, to.model.id)
    },
    getCategories() {
      this.$vs.loading({
        container: this.$refs.test,
        scale: 0.45,
      })
      axios
        .get('category')
        .then(response => {
          if (response.data) {
            this.treeData = response.data
          }
          this.$vs.loading.close(this.$refs.test)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getCategory(id) {
      // this.$vs.loading({
      //   container: this.$refs.test,
      //   scale: 0.45,
      // });

      try {
        const response = await axios.get(`category/${id}`)
        if (response.data) {
          // this.$vs.loading.close(this.$refs.test);
          return response.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    openParams(node, buttonId) {
      const { checked = false } = node
      this.optionsState = true
      this.currentCat = node.id
      this.$vs.loading({
        container: this.$refs.test,
        scale: 0.45,
      })

      axios
        .get('/api/shop/categories/get/' + this.currentCat)
        .then(response => {
          if (response.data.data) {
            this.data = response.data.data
            this.$vs.loading.close(this.$refs.test)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    addCategory(id) {
      let name = prompt('Введите название категории')
      if (!name) return false
      this.$vs.loading({
        container: this.$refs.test,
        scale: 0.45,
      })
      let data = {
        title: name,
        description: '',
        parent_id: id,
        level: 1,
        has_child: 0,
        slug: '',
      }
      axios
        .post('category', data)
        .then(response => {
          this.$vs.loading.close(this.$refs.test)
          this.getCategories()
        })
        .catch(error => {
          console.log(error)
        })
    },
    expandTree() {},
    async addSubCategory(category) {
      this.$vs.loading({
        container: this.$refs.test,
        scale: 0.45,
      })
      try {
        let data = {
          title: 'Под Категория',
          description: '',
          parent_id: category.id,
          level: category.level + 1,
          has_child: 0,
          slug: '',
        }

        const response = await axios.post('category', data)

        if (response.data) {
          const subCategory = await this.getCategory(response.data.id)
          this.$refs.tree.addNode(category, subCategory)
        }
        this.$vs.loading.close(this.$refs.test)
      } catch (error) {
        console.log(error)
      }
    },
    async deleteCategory(category, parent, index) {
      this.$vs.loading({
        container: this.$refs.test,
        scale: 0.45,
      })
      try {
        if (category.level === 1) {
          await axios.delete(`category/${category.id}`)
          this.getCategories()
        } else {
          // const parentNode = category.parent()
          // const index = parentNode.children.findIndex(e => e.id === category.id)
          await axios.delete(`category/${category.id}`)
          this.$refs.tree.delNode(category, parent, index)
        }
      } catch (error) {
        console.log(error)
      }
      this.$vs.loading.close(this.$refs.test)
    },
  },
  created() {
    this.getCategories()
  },
}
</script>

<style lang="scss">
button.btn-async {
  background: rgba(var(--vs-warning), 0.15);
}

button.btn-delete {
  background: rgba(var(--vs-danger), 0.15);
}

.treebtn1 {
  border: 1px solid #7367f0;
  border-radius: 5px;
  color: white;
  background-color: #7367f0;
  font-size: 12px;
  padding: 3px;
}

.treebtn2 {
  @extend .treebtn1;
  margin-right: 10px;
}

.treebtn3 {
  @extend .treebtn1;
}
</style>
