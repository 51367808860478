<template>
    <div
        :style="styleObj"
        :draggable="isDraggable"
        @drag.stop="drag"
        @dragstart.stop="dragStart"
        @dragover.stop="dragOver"
        @dragenter.stop="dragEnter"
        @dragleave.stop="dragLeave"
        @drop.stop="drop"
        @dragend.stop="dragEnd"
        class="dnd-container"
    >
        <div
            style="display: flex;align-items: center"
            :class="{ 'is-clicked': isClicked, 'is-hover': isHover }"
            @click="toggle"
            @mouseover="mouseOver"
            @mouseout="mouseOut"
        >
            <div
                :style="{ 'padding-left': (this.depth - 1) * 24 + 'px' }"
                :id="model.id"
                class="treeNodeText"
            >
                <slot :nodeName="model.title" :isClicked="isClicked">
          <span
              :class="[
              open ? 'nodeOpened' : '',
              isClicked ? 'nodeClicked' : '',
              model.children && model.children.length > 0
                ? 'vue-drag-node-icon'
                : 'no-vue-drag-node-icon',
            ]"
          ></span>
                    <span class="text">{{ model.title }} (id: {{model.id}})</span>
                </slot>
            </div>
            <div style="margin-left: 10px">
                <span class="action-icon" @click.stop="editNode">
                    <svg width="15" aria-hidden="true" focusable="false" data-prefix="far" data-icon="edit" role="img"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
                         class="svg-inline--fa fa-edit fa-w-18"><path fill="currentColor"
                                                                      d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"
                                                                      class=""></path></svg>
                </span>
                <span class="action-icon" @click.stop="deleteNode">
<svg width="12" aria-hidden="true" focusable="false" data-prefix="far" data-icon="trash-alt" role="img"
     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-trash-alt fa-w-14"><path
    fill="currentColor"
    d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"
    class=""></path></svg>
                </span>
                <span class="action-icon" @click.stop="addCategory">
<svg width="12" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus" role="img"
     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path
    fill="currentColor"
    d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"
    class=""></path></svg>
                </span>
            </div>
        </div>
        <div class="treeMargin" v-show="open" v-if="isFolder">
            <drag-node
                v-for="item2 in model.children"
                :allowDrag="allowDrag"
                :allowDrop="allowDrop"
                :depth="increaseDepth"
                :model="item2"
                :key="item2.id"
                :defaultText="defaultText"
                :disableDBClick="disableDBClick"
            ></drag-node>
        </div>
    </div>
</template>

<script>
    let id = 1000;
    let fromData = null;
    let toData = null;
    let nodeClicked = undefined; // Attention: 递归的所有组件共享同一个＂顶级作用域＂（这个词或许不太正确，但就这个意思）．即：共享上面这几个let变量．这为实现当前节点的高亮提供了基础．
    let rootTree = null; // vue-drag-tree组件引用

    import {findRoot, exchangeData} from "./util";

    export default {
        name: "DragNode",
        data() {
            return {
                open: false,
                isClicked: false, // 当前节点被点击
                isHover: false, // 当前节点被hvoer
                styleObj: {
                    //节点样式
                    opacity: 1,
                },
            };
        },
        props: {
            model: Object,
            allowDrag: {
                type: Function,
                default: () => true,
            },
            allowDrop: {
                type: Function,
                default: () => true,
            },
            defaultText: {
                // 填加节点时显示的默认文本．
                type: String,
                default: "New Node",
            },
            depth: {
                type: Number,
                default: 0,
            },
            disableDBClick: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            isFolder() {
                return this.model.children && this.model.children.length;
            },
            increaseDepth() {
                return this.depth + 1;
            },
            isDraggable() {
                return this.allowDrag(this.model, this);
            },
        },
        methods: {
            editNode() {
                rootTree.emitEdit(this.model);
            },
            deleteNode() {
                rootTree.emitDelete(this.model);
            },
            toggle() {
                if (this.isFolder) {
                    this.open = !this.open;
                }
                // 调用vue-drag-tree的父组件中的方法,以传递出当前被点击的节点的id值
                //　API: 对外开放的当前被点击节点的信息
                rootTree.emitCurNodeClicked(this.model, this);

                // 纪录节点被点击的状态
                this.isClicked = !this.isClicked;

                // 用户需要节点高亮
                // 第一次点击当前节点．当前节点高亮，遍历重置其他节点的样式
                if (nodeClicked != this.model.id) {
                    let treeParent = rootTree.$parent;

                    // 遍历重置所有树组件的高亮样式
                    let nodeStack = [treeParent.$children[0]];
                    while (nodeStack.length != 0) {
                        let item = nodeStack.shift();
                        item.isClicked = false;
                        if (item.$children && item.$children.length > 0) {
                            nodeStack = nodeStack.concat(item.$children);
                        }
                    }
                    // 然后把当前节点的样式设置为高亮
                    this.isClicked = true;

                    // 设置节点为 当前节点
                    nodeClicked = this.model.id;
                }
            },
            addCategory() {
                rootTree.emitAdd(this.model);
            },
            changeType() {
                // 如果用户禁用了双击增加item，什么都不做
                if (this.disableDBClick) {
                    return;
                }
                // 用户需要高亮-->才纪录当前被点击节点
                if (this.currentHighlight) {
                    nodeClicked = this.model.id;
                }
                if (!this.isFolder) {
                    this.$set(this.model, "children", []);
                    this.addChild();
                    this.open = true;
                    this.isClicked = true;
                }
            },
            mouseOver(e) {
                this.isHover = true;
            },
            mouseOut(e) {
                this.isHover = false;
            },
            addChild() {
                this.model.children.push({
                    title: this.defaultText,
                    id: id++,
                });
            },
            removeChild(id) {
                // 获取父组件的model.children
                let parent_model_children = this.$parent.model.children;

                // 在父组件model.children里删除
                for (let index in parent_model_children) {
                    // 找到该删的id
                    if (parent_model_children[index].id == id) {
                        parent_model_children = parent_model_children.splice(index, 1);
                        break;
                    }
                }
            },
            drag(e) {
                fromData = this;
                rootTree.emitDrag(this.model, this, e);
            },
            dragStart(e) {
                e.dataTransfer.effectAllowed = "move";
                e.dataTransfer.setData("text/plain", "asdad");
                return true;
            },
            dragOver(e) {
                e.preventDefault();
                rootTree.emitDragOver(this.model, this, e);
                return true;
            },
            dragEnter(e) {
                if (this._uid !== fromData._uid) {
                    this.styleObj.opacity = 0.5;
                }
                rootTree.emitDragEnter(this.model, this, e);
            },
            dragLeave(e) {
                this.styleObj.opacity = 1;
                rootTree.emitDragLeave(this.model, this, e);
            },
            drop(e) {
                e.preventDefault();
                this.styleObj.opacity = 1;
                // 如果判断当前节点不允许被drop，return;
                if (!this.allowDrop(this.model, this)) {
                    return;
                }
                toData = this;
                exchangeData(rootTree, fromData, toData);
                rootTree.emitDrop(this.model, fromData, toData);
            },
            dragEnd(e) {
                rootTree.emitDragEnd(this.model, this, e);
                return;
            },
        },
        created() {
            rootTree = findRoot(this);
        },
    };
</script>

<style lang="scss">
    .dnd-container {
        background: #fff;
    }

    .dnd-container .is-clicked {
        background: #e5e9f2;
    }

    .dnd-container .is-hover {
        background: #e5e9f2;
    }

    .item {
        cursor: pointer;
    }

    .bold {
        font-weight: bold;
    }

    .text {
        font-size: 14px;
    }

    .treeNodeText {
        height: 34px;
        box-sizing: border-box;
        width: fit-content;
        font-size: 18px;
        color: #324057;
        display: flex;
        align-items: center;
    }

    .changeTree {
        width: 16px;
        color: #324057;
    }

    .vue-drag-node-icon {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 10px;
        margin-right: 8px;
        border-left: 4px solid grey;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-right: 0 solid yellow;
        transition: transform 0.3s ease-in-out;
    }

    .no-vue-drag-node-icon {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 10px;
        margin-right: 8px;
        border-left: 4px solid grey;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-right: 0 solid yellow;
        transition: transform 0.3s ease-in-out;
        opacity: 0;
    }

    .nodeClicked {
    }

    .nodeOpened {
        transform: rotate(90deg);
    }

    .action-icon {
        padding: 3px;
        display: inline-block;
        transition: color ease .3s;

        &:hover {
            cursor: pointer;
        }
    }
</style>
